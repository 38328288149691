import React from 'react'

import '../GlobalStyle.scss'
import './DnnaeImprovementsDetails.scss'

const DnnaeImprovementsDetails = ({
    improvementQantity,
    improvementText
}) => {
    return (
        <div className='globalColumnControl' 
            style={{
                    width:'182px',
        }}>
            <h1 className="quantity_Styling">
                {improvementQantity}
            </h1>
            <p className='globalParagraphStyle' 
                style={{
                    textAlign:'center',
                }}
            >
                {improvementText}
            </p>
        </div>
    )
}

export default DnnaeImprovementsDetails
