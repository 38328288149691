import * as React from "react"
import { useState } from 'react'
import Layout from "../components/layout/layout"
import LandingPageHeadingAndTagline from '../components/LandingPageHeadingAndTagline/LandingPageHeadingAndTagline'
import NothingOfYourInterest from '../components/NothingOfYourInterest/NothingOfYourInterest'
import InfoSummaryCardContainer from '../components/InfoCard/InfoSummaryCardContainer'
import DnnaeImporvementsContainer from '../components/DnnaeImprovements/DnnaeImporvementsContainer'
import HighlightedPerksContainer from '../components/HighlightedPerksContainer/HighlightedPerksContainer'
import CostumerContainer from '../components/CostumerContainer/CostumerContainer'
import ContactPanel from '../components/ContactPanel/ContactPanel'
import InfoSummaryCardsMapContainer from "../components/InfoSummaryCardsMapContainer/InfoSummaryCardsMapContainer"
import DnnaeInformation from "../components/DnnaeInformation/DnnaeInformation"




import 'bootstrap/dist/css/bootstrap.min.css';

import rocket from '../components/Images/Rocket.webp'
import pencilItIcon from '../components/Images/PencilItLogo.svg'
import emailSummonIcon from '../components/Images/EmailSummonLogo.svg'
import robot from '../components/Images/Robot.png'

import magnifier from '../components/Images/Magnifier.png'
import check from '../components/Images/Check.png'
import calendar from '../components/Images/Calendar.png'
import messageLogo from '../components/Images/MessageLogo.png'

import faq from '../components/Images/FAQ.png'
import networking from '../components/Images/Networking.png'
import lock from '../components/Images/Lock.png'
import chart from '../components/Images/Chart.png'
import link from '../components/Images/Link.png'
import dashboard from '../components/Images/Dashboard.png'
import miniRocket from '../components/Images/MiniRocket.png'



import crownUserLogo from '../components/Images/CrownUserLogo.png'
import target from '../components/Images/Target.png'
// import messageLogo from '../components/Images/MessageLogo.png'
import usersLogo from '../components/Images/UsersLogo.png'
import recruitmentAssitant from '../components/Images/RecruitmentAssitant.png'
import FunctionalityCardContainer from "../components/FunctionalityCardContainer/FunctionalityCardContainer"
import WeAreDifferent from "../components/WeAreDifferent/WeAreDifferent"
import { Container } from "reactstrap"
import { Helmet } from "react-helmet"



const IndexPage = () => {

  const [NameState, setNameState] = useState('')
  const [EmailState, setEmailState] = useState('')
  const [LinkedInIdState, setLinkedInIdState] = useState('')
  const [PhoneState, setPhoneState] = useState('')
  const [CompanyState, setCompanyState] = useState('')
  const [MessageState, setMessageState] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const nameRegex = /^[a-z ,.'-]+$/gi
  
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
   
  const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/g

  const companyRegex = /^(\(([^)]+)\))?[[:punct:]]?\p{Lu}+(?:[\s'-]?[\p{L}\d]+)+(\(([^)]+)\))*$/g

  const handleChange = (e, setState, validate, re) => {
    const newValue = e.target.value
    // if(validate(newValue, re)){
      setState(newValue)
    // }
    // else{
    //   alert("Invalid Characters")
    // }
  }

  const handleSubmit = async () => {
    try{
      setIsLoading(true)
      if(NameState==='' ||
      LinkedInIdState==='' ||
      EmailState==='' ||
      CompanyState==='' ||
      MessageState==='' ||
      PhoneState===''){
        alert("Please fill the asterik fields")
      }
      else{
        await fetch('https://dnnae-api-prod-bdhweedyzq-uc.a.run.app/public/contactForm/send',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: NameState,
            linkedInId: LinkedInIdState,
            workEmail: EmailState,
            companySize: CompanyState,
            message: MessageState,
            phone: PhoneState
          })

        })
          
        setIsLoading(false)
        alert("Message Sent")
      }
    }
    catch(e) {
      
      setIsLoading(false)
      console.log(e)
      
    }
  }

  return (
    <div>
      <Helmet title="DNNae | Home"/>
      <Layout 
        containerStyle={{
          position: 'relative'
        }}
        selectedState='index'
        pageTitle='Home'    
      >
      <Container
      
        style={{
          maxWidth: '1920px',
        }}
      >
        <LandingPageHeadingAndTagline
          heading="Grow your fees by 175%"
          // heading2={`175%`}
          tagline='Completely automate research, sourcing, and all your other recruiting bottlenecks. Focus on your client and high touch candidates.'
          buttonName='Contact Sales'
          // headingColorStyle={{
          //   color: '#EF5555'
          // }}
          // conatinerStyle={{
          //   background: 'rgba(242, 166, 104, 0.08)'
          // }}
          pngSrc={rocket}
          containerClassName='landingPageHeadingAndTagline_Container_Index'
          pngSrcClassName='landingPageHeadingAndTagline_Image_Index'
        />
        <NothingOfYourInterest
          
          heading='Assistant Overview'
          subTitle='DNNae - Executive AI Recruiting Assistant'
          description='Automate your hiring cycle, get the talent that’s right for you, and minimize hiring costs—all at scale.'
          description_Style={{
            padding: '0px'
          }}
          containerStyle={{
            textAlign: 'center',
            justifyContent: 'center',
            margin: '120px 7% 0px 7%',
          }}
        />

        <FunctionalityCardContainer

          // containerStyle={{
          //   margin: '120px 16% 0px 16%',
      
          // }}
        
          functionalityData={[
            {
              index:1,
              subHeading:'Increase your candidate conversion by 300%',
              pngSrc: chart,
              description: 'Autonomously reach out to potential candidates through email, social media, or text. Get DNNae to design the best outreach campaign for maximum conversion.',
            },{
              index:2,
              subHeading:'Find DEI candidates instantaneously',
              pngSrc: miniRocket,

              description: 'Don’t waste any time sifting through endless profiles to find diverse candidates. Find all your candidates, instantaneously',
            },{
              index:3,
              subHeading:'Hire as a team',
              pngSrc: usersLogo,

              description: 'Use DNNae’s tribe feature to calibrate your search with instant direct feedback from your entire team. Comment on profiles and reach out as a team.',
            },{
              index:4,
              subHeading:'Get to your top 100 candidates in minutes',
              pngSrc: target,

              description: 'Don’t spend weeks looking for the right candidates. DNNae’s AI tech will recommend your top 100 candidates based on profiles you like.',
            },{
              index:5,
              subHeading:'Never dread your ATS',
              pngSrc: dashboard,

              description: 'Want the data but hate capturing steps and candidates in your ATS? DNNae has got you covered. Automatically capture all your candidates and your funnel steps in your ATS, autonomously.',
            },{
              index:6,
              subHeading:'Never schedule another meeting',
              pngSrc: calendar,

              description: 'Let DNNae schedule all your meetings for you. One on One screens, Followup meetings with other team members or clients, or group interviews. DNNae will take care of it for you!',
            },{
              index:7,
              subHeading:'Need an org chart?',
              pngSrc: networking,

              description: 'Get DNNae to map out the entire organizational chart of your client.',
            },
          ]}
        
        />



        {/* <DnnaeInformation
          heading='recruitment assistant'
          subTitle='DNNae - Efficient Recruiting'
          description='Automate top-of-the-funnel tasks on LinkedIn. Our software enables hiring managers, recruiters, and teams to hire hidden talent with a job description and a few clicks—saving you countless hours of repetitive grunt work.'
          buttonLabel='Learn More'
          mainLinkTo='/recruitmentAssistant'
          subTitle_Style={{
                  width:'76.5%',
                  color: '#0E3F89',
                }}
          additionalInfoArray1={[{
            pngSrc: crownUserLogo,
            description: 'Paint us a picture of the ideal candidate using a job description or a specific profile'
          },{
            pngSrc: target,
            description: 'Our unique technology takes your vision and extracts the data to deliver high-quality prospects'
          }]}
          
          additionalInfoArray2={[{
            pngSrc: messageLogo,
            description: 'Let our smart-messaging perfect the art of high-converting conversations'
          },{
            pngSrc: usersLogo,
            description: 'Hire as a team by sharing insights, commenting and tracking progress—all in one place'
          }]}
        /> */}


        {/* <InfoSummaryCardContainer 
            
            containerStyle={{
              margin: '0px 7%'
            }}
            
            mainHeading='DNNae - Efficient Recruiting'
            mainDescription='Automate your recruitment process on LinkedIn. Our software enables hiring managers, recruiters, and teams to hire quality talent efficiently—saving up to 20 work hours per week.'
            mainLinkTo='/recruitmentAssistant'
            mainButtonLabel='Learn More'
            additionalInfoArray1={[{
              svgSrc: searchIcon,
              description: 'Source quality prospects from LinkedIn'
            },{
              svgSrc: messageIcon,
              description: 'Initiate high-converting conversations'
            }]}
            
            additionalInfoArray2={[{
              svgSrc: calendarIcon,
              description: 'Schedule multiple meetings in a flash'
            },{
              svgSrc: usersIcon,
              description: 'Hire as a team—share feedback and insights'
            }]}
        />   */}

        <DnnaeImporvementsContainer 

          improvements={[

            {
                id: 1,
                quantity:'20',
                text:'Hours saved in a week',
            },{
                id: 2,
                quantity:'32%',
                text:'Increased productivity',
            },{
                id: 3,
                quantity:'21%',
                text:'Enhanced team efficiency',
            }
          ]}
        />

        {/* <NothingOfYourInterest
        
          heading='other assistants by dnnae'
          containerStyle={{

            justifyContent: 'center',
            margin: '48px 7%'
          }}
        /> */}
        {/* <InfoSummaryCardsMapContainer
        
          infoCard={[
            {
              infoSummaryCardStyle:{justifyContent: 'left'},
              containerStyle:{maxWidth: '48%'},
              svgSrc: pencilItIcon,
              mainHeading:'Pencilit',
              mainDescription:'Instant scheduling - set your availability and let candidates fill in appointments without the back and forth.',
          },{
              infoSummaryCardStyle:{justifyContent: 'left'},
              containerStyle:{maxWidth: '48%'},
              svgSrc: emailSummonIcon,
              mainHeading:'Email Summon',
              mainDescription:'Instant scheduling - set your availability and let candidates fill in appointments without the back and forth.',
          },
          
        ]}
        /> */}
      {/* <HighlightedPerksContainer 
      
        containerStyle={{
          margin: '120px 7%'
        }}
        mainHighlightHeading = 'In the pipeline to join the arsenal'

        highlightComponents={[
          {
            logoSrc:(
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18" cy="18" r="18" fill="#297AF7"/>
              <path d="M9 24V12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13 20L16 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16 16L21 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21 18L25 13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M27 24H9" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>),
            title: 'Sales Assistant',
            paragraph: `Accelerate your revenue with DNNae Sales Assistant. We streamline the sales process by automating lead generation, minimizing churn and carrying out pre-sales conversations on your behalf.`,
            status: 'Coming Soon'
          },{ 
              logoSrc:(
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18" cy="18" r="18" fill="#297AF7"/>
                <path d="M18 7V29" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M23 11H15.5C14.5717 11 13.6815 11.3687 13.0251 12.0251C12.3687 12.6815 12 13.5717 12 14.5C12 15.4283 12.3687 16.3185 13.0251 16.9749C13.6815 17.6313 14.5717 18 15.5 18H20.5C21.4283 18 22.3185 18.3687 22.9749 19.0251C23.6313 19.6815 24 20.5717 24 21.5C24 22.4283 23.6313 23.3185 22.9749 23.9749C22.3185 24.6313 21.4283 25 20.5 25H12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>),
              title: 'Financial Assistant',
              paragraph: `Optimize your finances with DNNae Finance Assistant. Keep your eye on cash flow, automate routine spendings and maximize your money’s impact.`,
              status: 'Coming Soon'
          },{ 
              logoSrc:(
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18" cy="18" r="18" fill="#297AF7"/>
                <path d="M26 13H10C8.89543 13 8 13.8954 8 15V25C8 26.1046 8.89543 27 10 27H26C27.1046 27 28 26.1046 28 25V15C28 13.8954 27.1046 13 26 13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22 27V11C22 10.4696 21.7893 9.96086 21.4142 9.58579C21.0391 9.21071 20.5304 9 20 9H16C15.4696 9 14.9609 9.21071 14.5858 9.58579C14.2107 9.96086 14 10.4696 14 11V27" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              ),
              title: 'Legal Assistant',
              paragraph: `Modernize your legal processes. Auto-gather info to draft contracts,`,
              status: 'Coming Soon'}]}
      /> */}

    {/* <CostumerContainer 
      containerStyle={{
        margin: '120px 7% 0px 7%'
      }}
      mainLogos ={[
        {pngSrc: AirBnbDP},
        {pngSrc: HubSpotDP},
        {pngSrc: GoogleDP},
        {pngSrc: MicrosoftDP},
        {pngSrc: WalmartDP},
        {pngSrc: FedExDP}
      ]}
    
    />
      */}

        <WeAreDifferent
          containerStyle={{
              padding: '120px 7% 120px 7%',
              // margin: '120px 0px',
              background: '#F7FAFF'
          }}
          heading="We’re different - here’s why"
          cards={[
              {
                  id:1,
                  subTitle:"Supersmart",
                  description:`DNNae leverages the power of AI and Natural Language Processing to interpret and intuit your priorities, 
                  applying them to determine the best candidates out of millions—in a fraction of seconds.`
              },{
                  id:2,
                  subTitle:"Plays the Field",
                  description:`DNNae taps multiple platforms to connect with your candidates. Prospects not responding through LinkedIn? 
                  DNNae reaches out through email next, increasing the probability of engagement.`
              },{
                  id:3,
                  subTitle:"Almost Human",
                  description:`DNNae gets you— it replicates true human behavior while viewing profiles and sending messages, so there’s 
                  no risk to your account.`
              },
          ]}
        />
        
        <div 
          id="contactSalesPanel"
          style={{
            height:'94px', 
            marginTop: '28px',
            // border: '1px solid black'
            }} 
        ></div>
        <ContactPanel
          // containerStyle={{
          //   margin: '120px 7%'
          // }}
          heading='Contact us'
          subTitle='Book a demo now'
          statement1='Schedule a free personalized session today to learn more about DNNae and how we can help you crush your hiring goals.'
          statement2= "<br/>Fill out this form or contact us at <a href='mailto:sales@dnnae.com'>sales@dnnae.com</a>"
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          formRequirements= {[
            {
              id: 1,
              label:'Your Name',
              type: 'text',
              value: NameState,
              setState: setNameState,
              re: nameRegex,
              handleChange
            },{
              id: 2,
              label:'Your LinkedIn ID',
              type: 'text',
              value: LinkedInIdState,
              setState: setLinkedInIdState,
              handleChange
            },{
              id: 3,
              label:'Work Email',
              type: 'email',
              value: EmailState,
              setState: setEmailState,
              re: emailRegex,
              handleChange
            },{
              id: 4,
              label:'Phone',
              type: 'text',
              value: PhoneState,
              setState: setPhoneState,
              re: phoneRegex,
              handleChange
            },{ 
              id: 5,
              label:'Company Size',
              type: 'text',
              value: CompanyState,
              setState: setCompanyState,
              re: companyRegex,
              handleChange
            },{
              id: 6,
              label:'Message',
              type: 'textarea',
              value: MessageState,
              setState: setMessageState,
              handleChange
            }
          ]}
        />

      </Container>
    </Layout>
    </div>
  )
}

export default IndexPage
