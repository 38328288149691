import React, { useState }  from 'react'
import FunctionalityCard from '../FunctionalityCard/FunctionalityCard'
import NothingOfYourInterest from '../NothingOfYourInterest/NothingOfYourInterest'

import './FunctionalityCardContainer.scss'

const FunctionalityCardContainer = ({

    functionalityData,
    containerStyle,
}) => {

    const [flexState, setFlexState] = useState(true)

    return (
        <div>
            <div
                // style={{...containerStyle}}
                className='functionalityCardContainer'
            >
            {
                functionalityData.map( (functionality , id ) => {
                    return(
                            <NothingOfYourInterest
                                containerStyle={ 
                                                id%2 == 0 ? 
                                                    {
                                                        flexDirection: 'row-reverse', 
                                                        marginRight: '40px'
                                                    } : 
                                                    {
                                                        marginLeft: '40px'
                                                    } 
                                                }
                                pngSrc={functionality.pngSrc}
                                subHeading={functionality.subHeading}
                                description={functionality.description}
                                detail_Style={{
                                    justifyContent: 'center',
                                    minHeight: '280px',
                                    width: '65%',
                                    margin: '20px',
                                }}
                            />
                            
                    )
                })
            }
            </div>
            <div
                style={{...containerStyle}}
                className='functionalityCardContainer_Mobile'
            >
                {
                    functionalityData.map((functionality , id) => {
                        return(
                            <div>
                                <img
                                    style={{
                                        width: '82px',
                                    }}
                                    src={functionality.pngSrc}
                                />
                                <h4>
                                    {functionality.subHeading}
                                </h4>
                                <p>
                                    {functionality.description}
                                </p>
                            </div>
                        )
                    })
                }
            </div>
        </div>

)
}

export default FunctionalityCardContainer
