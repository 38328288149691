import React from 'react'


import DnnaeImprovementsDetails from './DnnaeImprovementsDetails'


import '../GlobalStyle.scss'
import './DnnaeImporvementsContainer.css'


const DnnaeImporvementsContainer = ({
    improvements,
    containerStyle,
}) => {
    return (
        <div 
            style={{...containerStyle}}
            className='dnnaeImprovementsSpacer'
            >
            <React.Fragment>
                <div className='dnnaeImporvementsContainer'>
                    
                    {
                        improvements.map((improv , idx) => <DnnaeImprovementsDetails
                                    
                                    key={idx}
                                    improvementQantity={improv.quantity}
                                    improvementText={improv.text}
                        />)
                    }

                </div>
            </React.Fragment>
        </div>
    )
}

export default DnnaeImporvementsContainer
