import React from 'react'

import './WeAreDifferent.scss'

const WeAreDifferent = ({

    heading,
    cards,
    containerStyle,
}) => {
    return (
        <div 
            style={{...containerStyle}}
            className='weAreDifferent'>
            <h1>{heading}</h1>

            <span className='weAreDifferentPanel'>
                {
                    cards.map((card, index) => {
                        return(
                            <div 
                                className='weAreDifferentCard'
                                key={index}>
                                <h2>{card.subTitle}</h2>
                                <p>{card.description}</p>
                            </div>
                        )
                    })
                }
            </span>
        </div>
    )
}

export default WeAreDifferent
